import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { HttpHelperService } from '../http-helper.service';
import { AdminOrgService } from './admin-org.service';
import { AdminPaginationService } from './admin-pagination.service';
import { BlankSkuService } from './blank-sku.service';
import { BlankService } from './blank.service';
import { CodeService } from './code.service';
import { LicenseEntityService } from './license-entity.service';
import { LicenseSliceService } from './license-slice.service';
import { LicenseService } from './license.service';
import { ManufacturerService } from './manufacturer.service';
import { ModelService } from './model.service';
import { OrgHomeService } from './org-home.service';
import { PandaTemplateService } from './panda-template.service';
import { PlayerHomeService } from './player-home.service';
import { PlayerSalesService } from './player-sales.service';
import { PlayersService } from './players.service';
import { SchoolHomeService } from './school-home.service';
import { SchoolLicenseAgreementsService } from './school-license-agreements.service';
import { SchoolSalesService } from './school-sales.service';
import { SchoolSportService } from './school-sport.service';
import { SchoolService } from './school.service';
import { TemplatesService } from './templates.service';
import { UserGroupsService } from './user-groups.service';
import { UserPayoutsService } from './user-payouts.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  closeDialog = new Subject();
  public isLoaderActive: Boolean = false;
  splitedString: string[] = []
  constructor(
    private httpHelper: HttpHelperService,
    private SchoolService: SchoolService,
    private SchoolHomeService: SchoolHomeService,
    private SchoolLicenseAgreementsService: SchoolLicenseAgreementsService,
    private UserPayoutsService: UserPayoutsService,
    private CodeService: CodeService,
    private PlayerHomeService: PlayerHomeService,
    private PlayerSalesService: PlayerSalesService,
    private SchoolSalesService: SchoolSalesService,
    private TemplatesService: TemplatesService,
    private BlankService: BlankService,
    private BlankSkuService: BlankSkuService,
    private LicenseService: LicenseService,
    private LicenseSliceService: LicenseSliceService,
    private LicenseEntityService: LicenseEntityService,
    private AdminPaginationService: AdminPaginationService,
    private AdminOrgService: AdminOrgService,
    private schoolSportsService : SchoolSportService,
    private router: Router,
    private userService: UserService,
    private PlayersService : PlayersService,
    private userGroupService:UserGroupsService,
    private pandaTemplateService:PandaTemplateService,
    private orgHomeService:OrgHomeService,
    private modelService: ModelService,
    private manufacturerService : ManufacturerService
  ) {}

  getDynamicBreadcrumbs() {
    if (this.router.url.includes('?')) {
      let result = this.router.url.trim().substring(0, this.router.url.trim().indexOf('?'));
      this.splitedString = result.split('/')
    }
    else
      this.splitedString = this.router.url.split('/')
    let routeArray = []
    let sumstr = ''
    for (let item of this.splitedString) {
      if (item) {
        sumstr = sumstr + '/' + item
        routeArray.push(sumstr)
      }
    }
    routeArray.splice(0, 1)
    let breadCrumbArray = [{ name: 'Home', link: `/${this.userService?.currentUser?.group.toLowerCase()}/home` }]
    for (let i = 0; i < routeArray.length; i++) {
      if (routeArray[i] != breadCrumbArray[0].link) {
        const lastEqualSignIndex = routeArray[i].lastIndexOf('/');
        const a_ = routeArray[i].substr(lastEqualSignIndex + 1);
        let capitalizeFirstLetter_ = a_.charAt(0).toUpperCase() + a_.slice(1);
        if (routeArray[i] === '/admin/templates/template-designs' || routeArray[i] === '/admin/templates/template-blanks')
          routeArray[i] = '/admin/templates';
        else if (routeArray[i] === '/org/templates/template-designs' || routeArray[i] === '/org/templates/template-blanks')
          routeArray[i] = '/org/templates'
        breadCrumbArray.push({ name: capitalizeFirstLetter_, link: routeArray[i] })
      }
    }
    return breadCrumbArray;
  }


  uploadFile(model, data, id, updatedEndpoint): Observable<any> {
    var finalEndpoint = '/images/';
    if (updatedEndpoint) finalEndpoint = updatedEndpoint
    return this.httpHelper.request<any>(`${model}/${id}${finalEndpoint}`, { body: data, method: 'POST' });
  }

  imageErrorHandle(event) {
    event.target.src = "assets/img/not-found.png";
  }

  resetAllStates() {
    this.SchoolService.resetState();
    this.SchoolHomeService.resetState();
    this.SchoolLicenseAgreementsService.resetState();
    this.UserPayoutsService.resetState();
    this.CodeService.resetState();
    this.PlayerHomeService.resetState();
    this.PlayerSalesService.resetState();
    this.SchoolSalesService.resetState();
    this.TemplatesService.resetState();
    this.BlankService.resetState();
    this.BlankSkuService.resetState();
    this.LicenseService.resetState();
    this.LicenseSliceService.resetState();
    this.LicenseEntityService.resetState();
    this.AdminPaginationService.resetState();
    this.AdminOrgService.resetState();
    this.PlayersService.resetState();
    this.userGroupService.resetState();
    this.pandaTemplateService.resetState();
    this.orgHomeService.resetState();
    this.schoolSportsService.resetState();
    this.manufacturerService.resetState();
  }


  downloadCSV(data: any[], fileName: string): void {
    const keys = Object.keys(data[0]);
    let csvContent = keys.join(",") + "\n";
    csvContent += data.map(r => {
      return keys.map(k => Object(r)[k] ?? '').join(',')
    }).join('\n');
    fileName += '.csv';
    let a = document.createElement('a');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8,' })
    a.href = window.URL.createObjectURL(blob);
    a.download = fileName;
    a.click();
  }


  encodeImagePath(
    key: string,
    height: number,
    width: number,
    bucket = 'ipods',
  ) {
    if (!key) {
      return '';
    }
    key = key.replace('https://ipods.s3.amazonaws.com/', '');
    const stringified = JSON.stringify({
      bucket,
      key,
      edits: {
        resize: {
          width,
          height,
          fit: 'inside',
        },
      },
    });
    const encoded = btoa(stringified);
    return `https://d2zjn3mzuwdf8t.cloudfront.net/${encoded}`;
  }



}
